.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

a {
  text-decoration: none;
  color: #0000AA;
}

/*ブログ用。通常のデザインはMuiTypographyのものが反映される。*/
h1 {
  font-size: 1.5rem;
}
h2 {
  font-size: 1.3rem;
}
h3, h4 {
  font-size: 1.2rem;
}
h5, h6 {
  font-size: 1.1rem;
}
@media screen and (max-width: 600px) {
  /* Material UI のxs(600px)以下（スマホ用） */
  h1 {
    font-size: 1.2rem;
  }
  h2 {
    font-size: 1.1rem;
  }
  h3, h4, h5, h6 {
    font-size: 1.0rem;
  }
  p {
    line-height: 2rem;
  }
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(0px)
  }
}
